import React, { useState } from 'react'
import banner from '../assets/career/contact-page-banner.jpg'
import InternForm from '../components/career/InternForm';
import JobForm from '../components/career/JobForm';
import Banner from '../components/common/Banner';
import { Helmet } from 'react-helmet-async';

const Career = () => {

    const [intern, setIntern] = useState(true);

    return (
        <div>
            <Helmet>
                <title>Track Always | Career</title>
            </Helmet>

            <Banner
                title={intern ? 'Internship' : 'Career'}
                description={intern ? 'Learn With Us' : 'Join Us'}
                banner={banner}
            />

            <div className='w-11/12 mx-auto flex flex-col md:flex-row gap-4 md:gap-0 justify-evenly items-center my-12'>
                <button disabled={intern} onClick={() => setIntern(!intern)} className={`py-4 px-8 md:px-16 md:text-xl rounded-lg text-white font-semibold ${intern ? 'bg-gray-500' : 'bg-primary'}`}>
                    APPLY FOR INTERNSHIP
                </button>
                <button disabled={!intern} onClick={() => setIntern(!intern)} className={`py-4 px-8 md:px-16 md:text-xl rounded-lg text-white font-semibold ${!intern ? 'bg-gray-500' : 'bg-primary'}`}>
                    APPLY FOR JOB
                </button>
            </div>

            {
                intern ?
                    <InternForm /> :
                    <JobForm />
            }
        </div>
    )
}

export default Career