import React, { useState, useEffect } from 'react';
import { now } from '../utils/dateTime';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toISOString().split('T')[0];
  const formattedTime = date.toISOString().split('T')[1].split('.')[0];
  return { formattedDate, formattedTime };
};

const Dashboard = () => {
  const [dateTime, setDateTime] = useState({ formattedDate: '', formattedTime: '' });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nowString = now().toISOString();
      setDateTime(formatDate(nowString));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='flex flex-col items-center text-xl'>
      <p>{dateTime.formattedDate}</p>
      <p>{dateTime.formattedTime}</p>
    </div>
  );
};

export default Dashboard;
