import React from 'react'

const Banner = ({title, description, banner}) => {
    return (
        <div className="relative bg-cover bg-center h-[30vh] lg:h-[40vh]" style={{ backgroundImage: `url(${banner})` }}>
            <div className="flex flex-col items-center justify-center bg-black h-full bg-opacity-50">
                <h3 className="text-white text-4xl md:text-8xl font-bold">{title}</h3>
                <p className="text-white md:text-2xl">{description}</p>
            </div>
        </div>
    )
}

export default Banner