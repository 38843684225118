import React, { useState, useRef } from 'react';
import { submitInternshipApplication } from '../../services/operations/internshipAPI';

const InternForm = () => {
  const [fullName, setFullName] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [email, setEmail] = useState('');
  const [collegeName, setCollegeName] = useState('');
  const [course, setCourse] = useState('');
  const [specialization, setSpecialization] = useState('');
  const [batch, setBatch] = useState('');
  const [resume, setResume] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const resumeInputRef = useRef(null);

  const [errors, setErrors] = useState({});

  const batches = ['2023', '2024', '2025', '2026', '2027', '2028'];

  const handleContactNoChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setContactNo(value);
      setErrors((prevErrors) => ({ ...prevErrors, contactNo: '' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, contactNo: 'Contact number cannot exceed 10 digits.' }));
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!fullName.trim()) {
      errors.fullName = 'Full Name is required';
    }
    if (!contactNo.trim()) {
      errors.contactNo = 'Contact No is required';
    }
    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
    }
    if (!collegeName.trim()) {
      errors.collegeName = 'College Name is required';
    }
    if (!course.trim()) {
      errors.course = 'Course is required';
    }
    if (!specialization.trim()) {
      errors.specialization = 'Specialization is required';
    }
    if (!batch.trim()) {
      errors.batch = 'Batch is required';
    }
    if (!resume) {
      errors.resume = 'Resume is required';
    } else if (resume.type !== 'application/pdf') {
      errors.resume = 'Resume must be a PDF file';
    }

    setErrors(errors);
    console.log(errors);
    console.log(Object.keys(errors).length === 0);
    return Object.keys(errors).length === 0;
  };

  const resetForm = () => {
    setFullName('');
    setContactNo('');
    setEmail('');
    setCollegeName('');
    setCourse('');
    setSpecialization('');
    setBatch('');
    setResume(null);
    resumeInputRef.current.value = '';
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append('name', fullName);
      formData.append('mobile', contactNo);
      formData.append('email', email);
      formData.append('college_name', collegeName);
      formData.append('course', course);
      formData.append('specialization', specialization);
      formData.append('batch', batch);
      formData.append('resume', resume);
      formData.append('message', message);

      setLoading(true)
      const result = await submitInternshipApplication(formData);
      if (result) {
        resetForm();
        setErrors({});
      }
      setLoading(false)
    }
    else {
      console.log("regex failed");
    }
  };

  return (
    <div className='my-16'>
      <form onSubmit={handleSubmit}>
        <div className='flex flex-col w-11/12 lg:w-10/12 mx-auto'>
          <div className='grid md:grid-cols-3 gap-5'>
            {/* Full Name */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="fullName">Full Name*</label>
              <input type="text" id="fullName" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
              {errors.fullName && <span className="text-red-500">{errors.fullName}</span>}
            </div>

            {/* Email */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="email">Email Address*</label>
              <input type="email" id="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
              {errors.email && <span className="text-red-500">{errors.email}</span>}
            </div>

            {/* Contact No */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="contactNo">Contact No*</label>
              <input type="number" id="contactNo" placeholder="Contact No" value={contactNo} onChange={handleContactNoChange} />
              {errors.contactNo && <span className="text-red-500">{errors.contactNo}</span>}
            </div>

            {/* College Name */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="collegeName">College Name*</label>
              <input type="text" id="collegeName" placeholder="College Name" value={collegeName} onChange={(e) => setCollegeName(e.target.value)} />
              {errors.collegeName && <span className="text-red-500">{errors.collegeName}</span>}
            </div>

            {/* Course */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="course">Course*</label>
              <input type="text" id="course" placeholder="Course" value={course} onChange={(e) => setCourse(e.target.value)} />
              {errors.course && <span className="text-red-500">{errors.course}</span>}
            </div>

            {/* Specialization */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="specialization">Specialization*</label>
              <input type="text" id="specialization" placeholder="Specialization" value={specialization} onChange={(e) => setSpecialization(e.target.value)} />
              {errors.specialization && <span className="text-red-500">{errors.specialization}</span>}
            </div>

            {/* Batch */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="batch">Passout Batch*</label>
              <select id="batch" value={batch} onChange={(e) => setBatch(e.target.value)}>
                <option value="" disabled>Select Batch</option>
                {batches.map((batch, index) => (
                  <option key={index} value={batch}>{batch}</option>
                ))}
              </select>
              {errors.batch && <span className="text-red-500">{errors.batch}</span>}
            </div>

            {/* Resume */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="resume">Resume*</label>
              <input type="file" id="resume" ref={resumeInputRef} onChange={(e) => setResume(e.target.files[0])} />
              {errors.resume && <span className="text-red-500">{errors.resume}</span>}
            </div>
          </div>

          {/* Message */}
          <div className='flex flex-col gap-2 mt-5'>
            <label htmlFor="message">Message</label>
            <textarea type="text" maxLength={100} id="message" onChange={(e) => setMessage(e.target.value)} />
          </div>

          {/* Note */}
          <p className='mt-5'>Note: Please reach out at <a href="tel:06263201897" className='border-b border-gray-800'>+91 6263201897</a>  for any assistance or issues encountered during the form submission process.</p>

          <div className='w-full flex justify-center items-center'>
            <button type="submit" disabled={loading} className='mt-8 py-4 px-12 rounded-md text-lg bg-primary text-white w-full md:w-fit'>
              {loading ? <span>Loading...</span> : <span>Submit</span>}
            </button>
          </div>
        </div>
      </form >
    </div >
  );
};

export default InternForm;
