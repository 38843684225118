import { jobEndPoints } from "../apis";
import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { getToken } from "../../utils/getToken";

export const submitJobApplication = async (formData) => {
  const toastId = toast.loading("Loading...");
  // console.log(jobEndPoints.SUBMIT_JOB_APPLICATION);
  let result = [];
  try {
    const response = await apiConnector(
      "POST",
      jobEndPoints.SUBMIT_JOB_APPLICATION,
      formData
    );
    // console.log("response: ", response);
    if (!response?.data?.success) {
      throw new Error("Could Not Submit Form.");
    }
    result = response?.data;
    toast.success("Form Submitted Successfully");
  } catch (error) {
    console.log("SUBMIT_JOB_APPLICATION API ERROR............", error);
    toast.error("Error Submitting Form");
    result = error.response?.data;
  }
  toast.dismiss(toastId);
  return result;
};

export const getJobApplication = async () => {
  const toastId = toast.loading("Loading...");
  // console.log(jobEndPoints.GET_JOB_APPLICATIONS);
  let result = [];
  const headers = await getToken();
  try {
    const response = await apiConnector(
      "GET",
      jobEndPoints.GET_JOB_APPLICATIONS,
      null,
      headers,
      null
    );
    // console.log("response: ", response);
    if (!response?.data?.success) {
      throw new Error("Could Not Fetch Form.");
    }
    result = response?.data;
    toast.success("Form Feched Successfully");
  } catch (error) {
    console.log("GET_JOB_APPLICATIONS ERROR............", error);
    toast.error(
      error.response.data.errors[0].type +
        error.response.data.errors[0].value +
        error.response.data.errors[0].msg
    );
    result = error.response?.data;
  }
  toast.dismiss(toastId);
  return result;
};
