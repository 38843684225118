import React, { useState } from 'react'
import banner from '../assets/about/about-banner.png'
import about_image from '../assets/about/about-image-1.png'
import CTAButton from '../components/common/CTAButton'
import story1_image from '../assets/about/our-story-image1.png'
import story2_image from '../assets/about/our-story-image2.png'
import story3_image from '../assets/about/our-story-image3.png'
import { submitSubscriptionForm } from '../services/operations/formAPI'
import Banner from '../components/common/Banner'
import { Helmet } from 'react-helmet-async';

const About = () => {
    const data = [
        {
            timeline: "2017",
            content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione explicabo consectetur iste, exercitationem facere fugiat laudantium sed incidunt mollitia. Iusto, ratione sequi! Vel facere, provident nihil incidunt voluptatibus, Iusto, ratione sequi! Vel facere, provident nihil incidunt voluptatibus.",
            alt: "story-image-3",
            image: story1_image,
            flex_direction_reverse: false
        },
        {
            timeline: "2022",
            content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione explicabo consectetur iste, exercitationem facere fugiat laudantium sed incidunt mollitia. Iusto, ratione sequi! Vel facere, provident nihil incidunt voluptatibus, Iusto, ratione sequi! Vel facere, provident nihil incidunt voluptatibus.",
            alt: "story-image-3",
            image: story2_image,
            flex_direction_reverse: true
        },
        {
            timeline: "2024 onwards",
            content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione explicabo consectetur iste, exercitationem facere fugiat laudantium sed incidunt mollitia. Iusto, ratione sequi! Vel facere, provident nihil incidunt voluptatibus, Iusto, ratione sequi! Vel facere, provident nihil incidunt voluptatibus.",
            alt: "story-image-3",
            image: story3_image,
            flex_direction_reverse: false
        },
    ];

    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const resetForm = () => {
        setEmail('');
    }

    const validateForm = () => {
        const errors = {};

        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const formData = {
                email: email
            }

            console.log(formData);

            setLoading(true)
            const result = await submitSubscriptionForm(formData);
            if (result) {
                resetForm();
                setErrors({});
            }
            setLoading(false)
        }
    };

    return (
        <div>
            <Helmet>
                <title>Track Always | About</title>
            </Helmet>

            <Banner
                title={'About Us'}
                banner={banner}
            />

            <div className='flex gap-16 justify-between items-center w-10/12 mx-auto my-16'>
                <div className='w-[45%] md:block hidden'>
                    <img src={about_image} alt="about_image" />
                </div>
                <div className='flex flex-col gap-8 md:w-[50%] lg:w-[45%]'>
                    <h3 className='text-xl text-primary text-center md:text-left'>- Who we are -</h3>
                    <h1 className='text-5xl font-bold text-primary text-center md:text-left'>About Us</h1>
                    <p className='text-sm md:text-lg text-center md:text-left'>
                        Welcome to Trackalways, your trusted partner in telematics and GPS solutions. Established in 2018, Trackalways has been at the forefront of providing innovative and reliable tracking solutions designed to empower businesses across various industries. Our commitment to excellence, customer-centric approach, and cutting-edge technology set us apart in the dynamic world of telematics.
                    </p>
                    <div>
                        <CTAButton linkto={'/contact'}>
                            Discover Our Team
                        </CTAButton>
                    </div>
                </div>
            </div>

            {/* About Us */}
            <div className='bg-primary text-white mt-16 mb-4'>
                <div className='w-11/12 mx-auto flex flex-col justify-between gap-8 items-center lg:p-16 py-8'>
                    <h1 className='text-2xl md:text-4xl lg:text-5xl font-bold text-center'>Why Choose Us?</h1>
                    <div className='flex flex-col gap-6'>
                        <p className='md:text-lg text-center'>
                            <span className='font-bold'>Proven Track Record:</span> With a history dating back to 2018, Trackalways has a proven track record of delivering reliable and effective telematics solutions.
                        </p>
                        <p className='md:text-lg text-center'>
                            <span className='font-bold'>Cutting-Edge Technology:</span> We stay ahead of the curve by embracing the latest technological advancements, ensuring our solutions are at the forefront of industry standards.
                        </p>
                        <p className='md:text-lg text-center'>
                            <span className='font-bold'>Customer Success Stories:</span> Explore success stories from businesses that have benefited from Trackalways solutions, showcasing the tangible impact we make on our clients' operations.
                        </p>
                    </div>
                </div>
            </div>

            {/* Mission | Team */}
            <div className='w-11/12 lg:10/12 mx-auto my-16 flex flex-col gap-12 md:gap-16 lg:gap-20 justify-center'>
                <div className='flex flex-col gap-4 md:gap-6 lg:gap-8 justify-center'>
                    <h1 className='text-primary text-3xl md:text-4xl lg:text-5xl font-bold text-center'>Our Mission</h1>
                    <div className='flex flex-col gap-6 justify-center items-center'>
                        <p className='text-sm md:text-md lg:text-xl text-center'>
                            At Trackalways, our mission is to revolutionize how businesses manage their assets, enhance operational efficiency, and ensure the safety and security of people and valuable resources. We are driven by a passion for leveraging technology to create solutions that make a tangible impact on our clients' success.
                        </p>
                    </div>
                </div>
                <div className='flex flex-col gap-4 md:gap-6 lg:gap-8 justify-center'>
                    <h1 className='text-primary text-3xl md:text-4xl lg:text-5xl font-bold text-center'>Team Track Always</h1>
                    <div className='flex flex-col gap-6 justify-center items-center'>
                        <p className='text-sm md:text-md lg:text-xl text-center'>
                            Our diverse team brings together a wealth of expertise in telematics, software development, customer support, and business strategy. Together, we collaborate to deliver solutions that drive success for our clients.
                        </p>
                    </div>
                </div>
            </div>

            {/* Our Story */}
            <div className='my-16 py-16 bg-primary text-white'>
                <div className='flex flex-col gap-8 w-11/12 mx-auto'>
                    <h1 className='text-[3.2rem] text-center font-bold'>Our Story</h1>
                    <div className='flex flex-col gap-8'>
                        {
                            data.map(({ timeline, content, image, alt, flex_direction_reverse }, index) => {
                                return (
                                    <div key={index} className={`flex ${flex_direction_reverse ? 'flex-col-reverse md:flex-row-reverse' : 'flex-col-reverse md:flex-row'} justify-between items-center gap-8 w-11/12 mx-auto`}>
                                        <p className='md:w-1/3 text-sm md:text-lg leading-6 text-center md:text-left'>{content}</p>
                                        <p className='py-2 px-4 rounded-lg bg-white text-primary font-bold text-md lg:text-xl'>{timeline}</p>
                                        <div className='w-1/3 hidden md:block'>
                                            <img src={image} alt={alt} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            
            {/* our ceo */}
            {/* <div className='bg-primary text-white mt-16 mb-4'>
                <div className='w-11/12 mx-auto flex flex-col md:flex-row-reverse md:gap-16 justify-between items-center md:p-16 py-8'>
                    <div className='w-[40%] hidden lg:block'>
                        <img src={about_image} alt="about_image" />
                    </div>
                    <div className='flex flex-col gap-8 lg:w-[45%]'>
                        <h1 className='text-2xl lg:text-5xl font-bold text-center lg:text-left'>Join Our Team</h1>
                        <p className='md:text-lg text-center lg:text-left'>
                            The company was started 6 years ago by providing RPA solutions to optimize business efficiency and maintenance services for web design and app development services. The company's timely delivery of solutions, and meticulous detailing caught the eye of our current overseas customer.
                        </p>
                        <div>
                            <Link to='/career'>
                                <div className={`text-center text-[13px] sm:text-[16px] px-6 py-3 rounded-md font-bold shadow-[2px_2px_0px_0px_rgba(255,255,255,0.18)] hover:shadow-none hover:scale-95 transition-all duration-200 bg-white text-primary`} >
                                    Apply Now
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* subscribe */}
            <div className='w-11/12 mx-auto py-12'>
                <div className='flex flex-col md:gap-4 justify-center items-center'>
                    <h1 className='text-[1.4rem] md:text-[2.4rem] lg:text-[3.2rem] font-bold text-center'>Subscribe to Our Newsletter</h1>
                    <p className='md:text-xl text-center mb-4'>Stay up to date with our latest news and products</p>

                    <form onSubmit={handleSubmit}>
                        <div className="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
                            <div className="relative w-full">
                                <label htmlFor="email" className="hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email address</label>
                                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <svg className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                                </div>
                                <input className="block p-3 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary focus:border-primary" placeholder="Enter your email" type="email" id="email" required={true} value={email} onChange={(e) => setEmail(e.target.value)} />
                                {errors.email && <span className="text-red-500">{errors.email}</span>}
                            </div>
                            <div>
                                <button type="submit" className="py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg border cursor-pointer bg-primary border-primary sm:rounded-none sm:rounded-r-lg hover:bg-primary focus:ring-4 focus:ring-primary">Subscribe</button>
                            </div>
                        </div>
                        <div className="mx-auto max-w-screen-sm text-sm text-center md:text-left text-gray-500">We care about the protection of your data. <a href="#" className=" cursor-pointer font-medium text-primary hover:underline">Read our Privacy Policy</a>.</div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default About