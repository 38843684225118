import React, { useState } from 'react'
import { submitBookdemoForm } from '../services/operations/formAPI';

const BookADemo = ({ isOpen, onClose }) => {
    const [fullName, setFullName] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [message, setMessage] = useState('');

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleContactNoChange = (e) => {
        const value = e.target.value;
        if (value.length <= 10) {
            setContactNo(value);
            setErrors((prevErrors) => ({ ...prevErrors, contactNo: '' }));
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, contactNo: 'Contact number cannot exceed 10 digits.' }));
        }
    };

    const validateForm = () => {
        const errors = {};

        if (!fullName.trim()) {
            errors.fullName = 'Name is required';
        }
        if (!contactNo.trim()) {
            errors.contactNo = 'Contact No is required';
        }
        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid';
        }
        if (!company.trim()) {
            errors.company = 'Company Name is required';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const resetForm = () => {
        setFullName('');
        setContactNo('');
        setEmail('');
        setCompany('');
        setMessage('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const formData = {
                name: fullName,
                email: email,
                mobile: contactNo,
                company: company,
                message: message
            }
            console.log(formData);

            setLoading(true)
            const result = await submitBookdemoForm(formData);
            if (result) {
                resetForm();
                setErrors({});
                onClose();
            }
            setLoading(false)
        }
    };

    const handleOnClose = (e) => {
        if (e.target.id === 'outside') {
            setErrors({});
            onClose();
        }
    }

    if (!isOpen) return null;

    return (
        <>
            <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-40 flex justify-center items-center' id='outside' onClick={handleOnClose}>
                <div className='shadow-lg w-10/12 md:w-6/12 lg:w-4/12 mx-auto bg-white rounded-xl p-8'>
                    <div className='text-center text-3xl font-bold mb-8'>Book A Demo</div>

                    <form onSubmit={handleSubmit}>
                        <div className='flex flex-col'>
                            {/* Full Name */}
                            <div className='flex flex-col gap-2'>
                                {/* <label htmlFor="fullName" className='text-lg'>Full Name *</label> */}
                                <input type="text" id="fullName" placeholder="Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                                {errors.fullName && <span className="text-red-500">{errors.fullName}</span>}
                            </div>

                            {/* Email */}
                            <div className='flex flex-col gap-2 mt-5'>
                                {/* <label htmlFor="email" className='text-lg'>Email Address *</label> */}
                                <input type="email" id="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                                {errors.email && <span className="text-red-500">{errors.email}</span>}
                            </div>

                            {/* Contact No */}
                            <div className='flex flex-col gap-2 mt-5'>
                                {/* <label htmlFor="contactNo" className='text-lg'>Contact No *</label> */}
                                <input type="number" id="contactNo" maxLength={10} placeholder="Contact No" value={contactNo} onChange={handleContactNoChange} />
                                {errors.contactNo && <span className="text-red-500">{errors.contactNo}</span>}
                            </div>

                            {/* Company Name */}
                            <div className='flex flex-col gap-2 mt-5'>
                                {/* <label htmlFor="company" className='text-lg'>Company *</label> */}
                                <input type="text" id="company" placeholder="Company" value={company} onChange={(e) => setCompany(e.target.value)} />
                                {errors.company && <span className="text-red-500">{errors.company}</span>}
                            </div>

                            {/* Message */}
                            <div className='flex flex-col gap-2 mt-5'>
                                {/* <label htmlFor="message" className='text-lg'>Message</label> */}
                                <textarea type="text" id="message" placeholder='Message' onChange={(e) => setMessage(e.target.value)} />
                            </div>

                            <div className='w-full flex justify-center items-center'>
                                <button type="submit" className='mt-8 py-4 px-12 rounded-md text-xl bg-primary text-white w-full'>
                                    {loading ? <span>Loading...</span> : <span>Submit</span>}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default BookADemo