import React, { useState } from 'react'
import { DASHBOARD_SIDEBAR_LINKS, DASHBOARD_SIDEBAR_BOTTOM_LINKS } from '../../data/navigation'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { logout } from '../../services/operations/authAPI'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../assets/logo/logo_white.png';
import ConfirmationModal from "../../components/common/ConfirmationMoal"
import { VscSignOut } from "react-icons/vsc"

const linkClass = 'flex items-center gap-2 font-light px-3 py-2 hover:bg-black/10 hover:no-underline rounded-sm test-base'

const Sidebar = () => {
    const { user, loading: profileLoading } = useSelector(
        (state) => state.profile
    )

    const { loading: authLoading } = useSelector((state) => state.auth)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const [confirmationModal, setConfirmationModal] = useState(null)

    if (profileLoading || authLoading) {
        return (
            <div className="grid h-[calc(100vh-3.5rem)] min-w-[220px] items-center border-r-[1px] border-r-richblack-700 bg-richblack-800">
                <div className="spinner"></div>
            </div>
        )
    }

    return (
        <div className='bg-white flex flex-col p-3 h-100 fixed border h-screen w-[15rem] z-[1000]'>
            <div className='flex p-2 bg-black/90 rounded-md justify-center items-center'>
                <img src={logo} alt="logo" width={150} />
            </div>
            <div className='flex-1 py-5 flex flex-col gap-1'>
                {
                    DASHBOARD_SIDEBAR_LINKS.map((item) => (
                        <SidebarLink key={item.key} item={item} />
                    ))
                }
            </div>
            <div className='flex flex-col gap-1 pt-2 border-top border-primary'>
                {
                    DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((item) => (
                        <SidebarLink key={item.key} item={item} />
                    ))
                }
                <div
                    onClick={() =>
                        setConfirmationModal({
                            text1: "Are you sure?",
                            text2: "You will be logged out of your account.",
                            btn1Text: "Logout",
                            btn2Text: "Cancel",
                            btn1Handler: () => dispatch(logout(navigate)),
                            btn2Handler: () => setConfirmationModal(null),
                        })}
                    className={classNames('cursor-pointer text-red-400 hover:text-red-600' ,linkClass)}
                >
                    <div className="flex items-center gap-x-2">
                        <VscSignOut className="text-xl" />
                        <span>Logout</span>
                    </div>
                </div>
            </div>
            {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
        </div>
    )
}

export default Sidebar

function SidebarLink({ item }) {
    const { pathname } = useLocation();
    // console.log(pathname);
    return (
        <Link to={item.path} className={classNames(pathname === item.path ? 'text-light bg-black/10 font-medium' : 'text-black', linkClass)}>
            <span className='text-xl me-2'>{item.icons}</span>
            {item.label}
        </Link>
    )
}