import React, { useEffect, useState } from "react";
import Spinner from '../../components/common/Spinner';
import { getContactApplication } from "../../services/operations/formAPI";

function ContactApplications() {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchBySubject, setSearchBySubject] = useState('');
    const [searchbyApplicationDate, setSearchbyApplicationDate] = useState('');
    const [searchbyMobile, setSearchbyMobile] = useState('');
    const [applicantsData, setApplicantsData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true)
        const data = await getContactApplication();
        console.log(data.data);
        if (data) {
            setApplicantsData(data.data);
        }
        else {
            setApplicantsData([]);
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchBySubject = (e) => {
        setSearchBySubject(e.target.value);
    };

    const handleSearchbyApplicationDate = (e) => {
        setSearchbyApplicationDate(e.target.value);
    };

    const handleSearchbyMobile = (e) => {
        setSearchbyMobile(e.target.value);
    };

    const filteredApplicants = applicantsData
        .filter(applicant => {
            const inputDate = searchbyApplicationDate ? new Date(searchbyApplicationDate) : null;
            const applicantDate = applicant.created_at ? new Date(applicant.created_at) : null;

            const includesIgnoreCase = (field, query) => {
                if (!query) return true;
                if (!field) return false;
                return field.toLowerCase().includes(query.toLowerCase());
            };

            const datesAreEqual = (date1, date2) => {
                if (!date1 || !date2) return true;
                return date1.toDateString() === date2.toDateString();
            };

            return (
                includesIgnoreCase(applicant.name, searchQuery) &&
                includesIgnoreCase(applicant.subject, searchBySubject) &&
                includesIgnoreCase(applicant.mobile, searchbyMobile) &&
                datesAreEqual(applicantDate, inputDate)
            );
        })
        .sort((a, b) => a.name.localeCompare(b.name));

        const formatDate = (date) => {
            return date.split('T')[0];
        }

    return (
        <div>
            <h1 className="text-2xl font-bold mb-4">Queries</h1>
            <div className="grid grid-cols-4 gap-4 mt-2 border-b py-4">
                <div className="flex flex-col">
                    <label htmlFor="searchQuery" className="mb-2">Name</label>
                    <input
                        type="text"
                        id="searchQuery"
                        placeholder="Search by Name..."
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
                    />
                </div>

                <div className="flex flex-col">
                    <label htmlFor="searchBySubject" className="mb-2">Subject</label>
                    <input
                        type="text"
                        id="searchBySubject"
                        placeholder="Search by Subject..."
                        value={searchBySubject}
                        onChange={handleSearchBySubject}
                        className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
                    />
                </div>

                <div className="flex flex-col">
                    <label htmlFor="searchbyMobile" className="mb-2">Mobile Number</label>
                    <input
                        type="text"
                        id="searchbyMobile"
                        placeholder="Search by Mobile Number..."
                        value={searchbyMobile}
                        onChange={handleSearchbyMobile}
                        className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
                    />
                </div>

                <div className="flex flex-col">
                    <label htmlFor="searchbyApplicationDate" className="mb-2">Application Date</label>
                    <input
                        type="date"
                        id="searchbyApplicationDate"
                        placeholder="Search by Application Date..."
                        value={searchbyApplicationDate}
                        onChange={handleSearchbyApplicationDate}
                        className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
                    />
                </div>
            </div>

            <div className="relativ mt-12 overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Mobile
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Email
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Subject
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Message
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Application Date
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <tr>
                                    <td colSpan="12" className="px-6 py-4 text-center">
                                        <Spinner />
                                    </td>
                                </tr>
                                :
                                filteredApplicants.length > 0 ? (
                                    filteredApplicants.map((applicant, index) => (
                                        <tr
                                            key={index}
                                            className="bg-white border-b"
                                        >
                                            <td
                                                scope="row"
                                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                            >
                                                {applicant.name}
                                            </td>
                                            <td className="px-6 py-4">{applicant.mobile}</td>
                                            <td className="px-6 py-4">{applicant.email}</td>
                                            <td className="px-6 py-4">{applicant.subject}</td>
                                            <td className="px-6 py-4">{applicant.message}</td>
                                            <td className="px-6 py-4">{formatDate(applicant.created_at)}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="12" className="px-6 py-4 text-center">
                                            No Records Found
                                        </td>
                                    </tr>
                                )

                        }
                    </tbody>
                </table>

                <nav class="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4" aria-label="Table navigation">
                    <span class="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">Showing <span class="font-semibold text-gray-900 dark:text-white">1-10</span> of <span class="font-semibold text-gray-900 dark:text-white">{filteredApplicants.length}</span></span>
                    <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                        <li>
                            <a href="#" class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</a>
                        </li>
                        <li>
                            <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">1</a>
                        </li>
                        <li>
                            <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">2</a>
                        </li>
                        <li>
                            <a href="#" aria-current="page" class="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">3</a>
                        </li>
                        <li>
                            <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">4</a>
                        </li>
                        <li>
                            <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">5</a>
                        </li>
                        <li>
                            <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default ContactApplications;
