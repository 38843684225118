import React, { useState } from 'react'
import { createNewUser } from '../../services/operations/authAPI';

const CreateUserModal = ({ isOpen, onClose }) => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const validateForm = () => {
        const errors = {};

        if (!fullName.trim()) {
            errors.fullName = 'Name is required';
        }
        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid';
        }
        if (!username.trim()) {
            errors.username = 'Username is required';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const resetForm = () => {
        setFullName('');
        setEmail('');
        setUsername('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const formData = {
                name: fullName,
                email: email,
                username: username,
            }
            console.log(formData);

            setLoading(true)
            const result = await createNewUser(formData);
            if (result) {
                resetForm();
                setErrors({});
                onClose();
            }
            setLoading(false)
        }
    };

    const handleOnClose = (e) => {
        if (e.target.id === 'outside') {
            setErrors({});
            onClose();
        }
    }

    if (!isOpen) return null;

    return (
        <>
            <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-40 flex justify-center items-center' id='outside' onClick={handleOnClose}>
                <div className='shadow-lg w-10/12 md:w-6/12 lg:w-4/12 mx-auto bg-white rounded-xl p-8'>
                    <div className='text-center text-3xl font-bold mb-8'>Create New User</div>

                    <form onSubmit={handleSubmit}>
                        <div className='flex flex-col'>
                            {/* Full Name */}
                            <div className='flex flex-col gap-2'>
                                <label htmlFor="fullName" className='text-lg'>Full Name *</label>
                                <input type="text" id="fullName" placeholder="Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                                {errors.fullName && <span className="text-red-500">{errors.fullName}</span>}
                            </div>

                            {/* Email */}
                            <div className='flex flex-col gap-2 mt-5'>
                                <label htmlFor="email" className='text-lg'>Email Address *</label>
                                <input type="email" id="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                                {errors.email && <span className="text-red-500">{errors.email}</span>}
                            </div>

                            {/* Username Name */}
                            <div className='flex flex-col gap-2 mt-5'>
                                <label htmlFor="username" className='text-lg'>Username *</label>
                                <input type="text" id="username" placeholder="Company" value={username} onChange={(e) => setUsername(e.target.value)} />
                                {errors.username && <span className="text-red-500">{errors.username}</span>}
                            </div>

                            <div className='w-full flex justify-center items-center'>
                                <button type="submit" className='mt-8 py-4 px-12 rounded-md text-xl bg-primary text-white w-full'>
                                    {loading ? <span>Loading...</span> : <span>Submit</span>}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CreateUserModal