import { toast } from "react-hot-toast";

import { apiConnector } from "../apiConnector";
import { internshipEndPoints } from "../apis";
import { getToken } from "../../utils/getToken";

export const submitInternshipApplication = async (formData) => {
  const toastId = toast.loading("Loading...");
  // console.log(internshipEndPoints.SUBMIT_INTERNSHIP_APPLICATION);
  let result = [];
  try {
    const response = await apiConnector(
      "POST",
      internshipEndPoints.SUBMIT_INTERNSHIP_APPLICATION,
      formData
    );
    // console.log('response: ', response);
    if (!response?.data?.success) {
      throw new Error("Could Not Submit Form.");
    }
    result = response?.data;
    toast.success('Form Submitted Successfully');
  } catch (error) {
    console.log("SUBMIT_INTERNSHIP_APPLICATION ERROR............", error);
    toast.error(error.response.data.errors[0].type + error.response.data.errors[0].value + error.response.data.errors[0].msg);
    result = error.response?.data;
  }
  toast.dismiss(toastId);
  return result;
};

export const getInternshipApplication = async () => {
  const toastId = toast.loading("Loading...");
  // console.log(internshipEndPoints.GET_INTERNSHIP_APPLICATIONS);
  let result = [];
  const headers = await getToken();
  try {
    const response = await apiConnector(
      "GET",
      internshipEndPoints.GET_INTERNSHIP_APPLICATIONS,
      null,
      headers,
      null
    );
    // console.log('response: ', response);
    if (!response?.data?.success) {
      throw new Error("Could Not Fetch Form.");
    }
    result = response?.data;
    toast.success('Form Fetched Successfully');
  } catch (error) {
    console.log("GET_INTERNSHIP_APPLICATIONS ERROR............", error);
    toast.error(error.response.data.errors[0].type + error.response.data.errors[0].value + error.response.data.errors[0].msg);
    result = error.response?.data;
  }
  toast.dismiss(toastId);
  return result;
};