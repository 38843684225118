import React from 'react'
import HomeHero from '../assets/home/home-hero.png'
import { FaCheckCircle } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import productimage2 from '../assets/home/product-screen.png'
import image3 from '../assets/images/asset-tracking-circle.svg'
import image4 from '../assets/images/analyze-image.avif'
import camera from '../assets/home/camera.svg'
import fob from '../assets/home/fob.svg'
import integrations from '../assets/home/integrations.svg'
import remote from '../assets/home/remote.svg'
import speed from '../assets/home/speed.svg'
import switches from '../assets/home/switches.svg'
import truck from '../assets/home/truck.svg'
import lowerPrice from '../assets/home/lower-price.svg'
import profits from '../assets/home/profits.svg'
import graph from '../assets/home/graph.svg'
import CTAButton from '../components/common/CTAButton'
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../redux/slices/modalSlice';
import BookADemo from './BookADemo';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  const isModalOpen = useSelector((state) => state.modal.isModalOpen);
  const dispatch = useDispatch();

  const handleOpenModal = () => dispatch(openModal());
  const handleCloseModal = () => dispatch(closeModal());

  return (
    <>
      <Helmet>
        <title>Track Always | Fleet</title>
      </Helmet>

      <div className='w-full h-full'>

        {/* hero section */}
        <div className='flex flex-col lg:flex-row justify-evenly items-center lg:items-start mx-auto w-11/12 mt-[5%] gap-12 md:gap-16 lg:gap-0'>
          <div className='flex flex-col items-center lg:items-start lg:w-[50%]'>
            <p className='text-secondary mb-4 lg:mb-8 text-center lg:text-left'>India's one of the Best Vehicle Tracking System and App</p>
            <p className='text-3xl lg:text-5xl mb-4 lg:mb-8 font-bold md:leading-tight text-center lg:text-left'>Track your fleet easily anywhere, anytime!</p>
            <p className='text-secondary lg:max-w-[700px] font-semibold lg:text-lg mb-6 md:mb-10 text-center lg:text-left'>Fleetsmart vehicle tracking offers a complete real time view of your fleet status and vehicle locations. Cloud based tracking software allows effective and efficient fleet management via any smart phone or desktop computer.</p>
            <button onClick={handleOpenModal} className='transition-all duration-200 ease-in-out border-2 text-primary border-primary rounded-xl text-[17px] font-bold min-w-[150px] py-[18px] px-[32px] text-center hover:text-white hover:bg-primary'>
              Book A Demo
            </button>
          </div>
          <div className='lg:w-[40%] lg:-mt-10'>
            <img src={HomeHero} alt="" width={1311} height={917} className=' object-cover w-full h-full' />
          </div>
        </div>

        {/* banner */}
        <div className='custom-gradient w-full flex items-center justify-center flex-col gap-2 md:gap-6 mt-12 md:mt-[5%] py-6'>
          <p className='font-semibold md:text-3xl lg:text-5xl capitalize text-center'>We make fleet tracking more manageable</p>
          <div className='flex flex-col md:flex-row md:gap-4 justify-evenly max-w-[800px] w-full'>
            <div className='md:text-lg font-medium flex gap-2 justify-center items-center'>
              <FaCheckCircle />
              Free Setup
            </div>
            <div className='md:text-lg font-medium flex gap-2 justify-center items-center'>
              <FaCheckCircle />
              Flexible Device Options
            </div>
            <div className='md:text-lg font-medium flex gap-2 justify-center items-center'>
              <FaCheckCircle />
              24/7 Customer Support
            </div>
          </div>
        </div>

        <div className='mx-auto max-w-maxContent w-11/12 mt-12 flex flex-col items-center justify-between'>
          <div className='flex flex-col md:flex-row gap-8 md:gap-2 justify-between items-center'>
            <div className='flex flex-col justify-center items-center md:items-start'>
              <p className='text-3xl lg:text-5xl font-extrabold mb-[5%] text-center md:text-left md:leading-tight'>Increase fleet visibility with our vehicle tracking app.</p>
              <p className='mb-[5%] text-center md:text-left'>See in near real-time a 360 degree view of your fleet’s daily operations so you can help to reduce costs, increase productivity and stay on top of vehicle maintenance, allowing you to focus on running your business.</p>
              <button onClick={handleOpenModal} className=' text-primary flex gap-2 justify-center items-center font-bold'>
                <span>Book A Demo</span>
                <FaArrowRightLong />
              </button>
            </div>
            <div className='md:w-[160%] lg:-mr-20'>
              <img src={productimage2} alt="" width={1311} height={917} className=' object-cover w-full h-full' />
            </div>
          </div>
        </div>

        {/* Benefits */}
        <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-12 w-11/12 py-[30px] mx-auto mt-12 md:mt-[5%]'>
          <div className='flex flex-col items-center gap-6'>
            <img className='max-h-[80px] text-[150px]' src={truck} />
            <p className='text-[26px] font-semibold'>Track Your Fleet</p>
            <p className='text-secondary text-[18px] font-medium text-center'>Live tracking is available, every 60 seconds, 24 hours a day from any global location on any desktop or mobile device with our fleet tracker GPS solutions.</p>
          </div>
          <div className='flex flex-col items-center gap-6'>
            <img className='max-h-[80px] text-[150px]' src={graph} />
            <p className='text-[26px] font-semibold'>Access Reports Easily</p>
            <p className='text-secondary text-[18px] font-medium text-center'>Fleetsmart provide a comprehensive report suite delivering information on a multitude of fleet management information.</p>
          </div>
          <div className='flex flex-col items-center gap-6'>
            <img className='max-h-[80px] text-[150px]' src={lowerPrice} />
            <p className='text-[26px] font-semibold'>Lower Costs</p>
            <p className='text-secondary text-[18px] font-medium text-center'>Utilise your fleet more efficiently, route optimally, reduce unauthorised usage, reduce excess idling and save money.</p>
          </div>
          <div className='flex flex-col items-center gap-6'>
            <img className='max-h-[80px] text-[150px]' src={profits} />
            <p className='text-[26px] font-semibold'>Increase Profits</p>
            <p className='text-secondary text-[18px] font-medium text-center'>Produce accurate time sheets, eliminate false overtime claims, save 10-15% on annual fuel spend.</p>
          </div>
        </div>

        <div className='flex flex-col lg:flex-row items-center justify-between mt-[5%] mx-auto max-w-maxContent w-11/12 gap-12 lg:gap-0'>
          <div className='flex flex-col justify-center items-center lg:items-start lg:w-[45%]'>
            <p className='text-3xl md:text-4xl lg:text-5xl mb-4 lg:mb-8 font-bold md:leading-snug lg:leading-tight text-center lg:text-left'>The most intuitive Fleet & Asset tracking system on the market.</p>
            <p className='text-secondary lg:max-w-[700px] font-semibold lg:text-lg mb-6 md:mb-10 text-center lg:text-left'>Accessible across multiple platforms including; Mac, PC, Tablet and Mobile - any time of the day, from anywhere in the world. Vehicle tracking has never been easier. The most flexible customer focused approach with multiple hardware options for all applications. Whatever the vehicle you are looking to track, from lorries to van tracking, let Fleetsmart take care of it all.</p>
            <div className='w-fit'>
              <CTAButton linkto={'/contact'}>Get Started</CTAButton>
            </div>
          </div>
          <div className='lg:w-[45%]'>
            <img src={image3} alt=""  className='w-full h-full' />
          </div>
        </div>

        <div className='flex flex-col-reverse lg:flex-row items-center justify-between mt-12 lg:mt-[5%] mx-auto max-w-maxContent w-11/12 gap-12 lg:gap-0'>
          <div className='lg:w-[45%]'>
            <img src={image4} alt=""  className='w-full h-full' />
          </div>
          <div className='flex flex-col justify-center items-center lg:items-start lg:w-[45%]'>
            <p className='text-3xl md:text-4xl lg:text-5xl mb-4 lg:mb-8 font-bold md:leading-snug lg:leading-tight text-center lg:text-left'>Analyse your fleet's performance over time</p>
            <p className='text-secondary lg:max-w-[700px] font-semibold lg:text-lg mb-6 md:mb-10 text-center lg:text-left'>See important trends by team, department or across the entire fleet. Our customisable dashboards make it easy to review your progress towards KPIs or budgets, with up-to-the-minute fleet analytics when you need them most.</p>
            <div className='w-fit'>
              <CTAButton linkto={'/contact'}>Get Started</CTAButton>
            </div>
          </div>
        </div>

        {/* The smart way to track */}
        <div className='mx-auto w-11/12 lg:w-10/12 flex flex-col items-center gap-12 justify-center mt-12 lg:mt-16' >
          <p className='text-3xl md:text-4xl lg:text-5xl mb-4 lg:mb-8 font-bold md:leading-snug lg:leading-tight text-center'>The smart way to track</p>
          <div className='grid md:grid-cols-2 gap-y-4 md:gap-y-16 md:gap-x-20 lg:w-11/12 mx-auto' >
            <div className='flex items-start justify-start gap-4'>
              <div>
                <img src={camera} alt="" className='h-[50px] max-w-[50px]'  />
              </div>
              <div className='flex-1'>
                <p className='text-[26px] mb-[20px] font-bold'>Dashboard Cameras</p>
                <p className='text-secondary text-[18px] font-medium mb-[20px]'>Identify risky driving and exonerate drivers with auto-uploaded HD video footage.</p>
              </div>
            </div>
            <div className='flex items-start justify-start gap-4'>
              <div>
                <img src={remote} alt="" className='h-[50px] max-w-[50px]'  />
              </div>
              <div className='flex-1'>
                <p className='text-[26px] mb-[20px] font-bold'>Remote Immobilisation</p>
                <p className='text-secondary text-[18px] font-medium mb-[20px]'>Increase vehicle security & improve stolen vehicle recovery rates with remote immobilisation via smartphone app.</p>
              </div>
            </div>
            <div className='flex items-start justify-start gap-4'>
              <div>
                <img src={switches} alt="" className='h-[50px] max-w-[50px]'  />
              </div>
              <div className='flex-1'>
                <p className='text-[26px] mb-[20px] font-bold'>Driver Privacy Switches</p>
                <p className='text-secondary text-[18px] font-medium mb-[20px]'>Comply with UK privacy laws allowing masking of tracked positional details for private usage.</p>
              </div>
            </div>
            <div className='flex items-start justify-start gap-4'>
              <div>
                <img src={fob} alt="" className='h-[50px] max-w-[50px]'  />
              </div>
              <div className='flex-1'>
                <p className='text-[26px] mb-[20px] font-bold'>Driver Identification Fobs</p>
                <p className='text-secondary text-[18px] font-medium mb-[20px]'>Identify individual drivers and monitor their driving style.</p>
              </div>
            </div>
            <div className='flex items-start justify-start gap-4'>
              <div>
                <img src={speed} alt="" className='h-[50px] max-w-[50px]'  />
              </div>
              <div className='flex-1'>
                <p className='text-[26px] mb-[20px] font-bold'>Road Speed Data</p>
                <p className='text-secondary text-[18px] font-medium mb-[20px]'>True speeding events through regularly updated 'road speed data.</p>
              </div>
            </div>
            <div className='flex items-start justify-start gap-4'>
              <div>
                <img src={integrations} alt="" className='h-[50px] max-w-[50px]'  />
              </div>
              <div className='flex-1'>
                <p className='text-[26px] mb-[20px] font-bold'>Public API for integration</p>
                <p className='text-secondary text-[18px] font-medium mb-[20px]'>Integrate our software into your business with api integrations.</p>
              </div>
            </div>
          </div>
          <CTAButton linkto={'/contact'}>Explore More</CTAButton>
        </div>

        <BookADemo isOpen={isModalOpen} onClose={handleCloseModal} />
      </div>
    </>
  )
}

export default Home