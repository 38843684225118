import React from 'react'
import { Link } from 'react-router-dom'
import logo1 from '../../assets/logo/logo_white.png'
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../../redux/slices/modalSlice';
import BookADemo from '../../pages/BookADemo';


const CustomFooter = () => {
    
  const isModalOpen = useSelector((state) => state.modal.isModalOpen);
  const dispatch = useDispatch();

  const handleOpenModal = () => dispatch(openModal());
  const handleCloseModal = () => dispatch(closeModal());

  return (
    <div className="bg-gray-950 text-white mt-16">
      <div className="flex flex-col md:flex-row justify-evenly mb-4 py-4 w-11/12 md:w-full mx-auto gap-6 md:gap-0">
        <div className="flex flex-col item-center">
          <img src={logo1} alt="logo" width={200} height={42}  />
          <div className='flex items-center gap-2'>
            <h3 className="text-white my-4">Follow Us</h3>
            <div className="flex gap-2">
              <a href='https://www.facebook.com/trackalways' target="_blank" className='text-xl'>
                <FaFacebookSquare />
              </a>
              <a href='https://www.linkedin.com/company/trackalways' target="_blank" className='text-xl'>
                <FaLinkedin />
              </a>
              <a href='https://www.instagram.com/trackalways?igsh=MWk2NjhnM2w5azFjdw==' target="_blank" className='text-xl'>
                <FaInstagramSquare />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start">
          <h3 className="text-white mb-4 font-bold">Quick Links</h3>
          <div className="flex flex-col justify-start items-start text-white gap-2">
            <Link to={"/"}>Home</Link>
            <Link to={"/career"}>Career</Link>
            <Link to={"/about"}>About Us</Link>
            <Link to={"/contact"}>Contact Us</Link>
            <button onClick={handleOpenModal}>Book Demo</button>
          </div>
        </div>
        <div className="flex flex-col items-start md:max-w-[30%]">
          <h3 className="text-white mb-4 font-bold">Get In Touch</h3>
          <div className="mb-2 flex">
            <div className="flex flex-col justify-start">
              <span>Head Office</span>
              <span>A-01, Yasoda Garden Bagnugaliya, Bhopal - 462043</span>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="flex flex-col justify-start">
              <span>Corporate Office</span>
              <span>ITI, Govindpura, Bhopal - 462043</span>
            </div>
          </div>
          <Link to="mailto:care@trackalways.in">
            <p className="mb-2 text-white">care@trackalways.in</p>
          </Link>
          <a href='tel:09131950173' className="mb-0">+91 9131950173</a>
        </div>
      </div>
      <div className="text-white text-center border-t border-white py-4">
        <p className="m-0">&copy; 2024
          <Link className="text-white" to="/"> Track Always</Link>, Inc. All Rights Reserved.
        </p>
      </div>
      <BookADemo isOpen={isModalOpen} onClose={handleCloseModal}/>
    </div>
  )
}

export default CustomFooter