import React from 'react';
import { Outlet } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import PageTop from '../common/PageTop';
import { Helmet } from 'react-helmet-async';
import StructuredData from '../../StructuredData';
import CustomFooter from '../common/Footer';
import BookADemo from '../../pages/BookADemo';

import { useDispatch, useSelector } from 'react-redux';
import { openModal, closeModal } from '../../redux/slices/modalSlice';
import Navbar2 from '../shared/Navbar2';

const OpenLayout = () => {
    const helmetContext = {};

    const isModalOpen = useSelector((state) => state.modal.isModalOpen);
    const dispatch = useDispatch();

    const handleOpenModal = () => dispatch(openModal());
    const handleCloseModal = () => dispatch(closeModal());

    return (
        <HelmetProvider context={helmetContext}>
            <Helmet>
                <title>Track Always</title>
                <meta name="description" content=""></meta>
                <link rel="canonical" href="https://www.trackalways.in" />
            </Helmet>

            <StructuredData />
            
            <Navbar2 onBookDemoClick={handleOpenModal} />
            <Outlet />
            <CustomFooter />
            <BookADemo isOpen={isModalOpen} onClose={handleCloseModal} />
            <PageTop />
        </HelmetProvider>
    )
}

export default OpenLayout