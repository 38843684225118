import {
    HiOutlineViewGrid,
    HiOutlineCube,
    HiOutlineQuestionMarkCircle,
    HiOutlineCog,
} from 'react-icons/hi';

export const DASHBOARD_SIDEBAR_LINKS = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        path: '/admin/dashboard',
        icons: <HiOutlineViewGrid />
    },
    {
        key: 'internship_applications',
        label: 'Intern Applications',
        path: '/admin/internshipApplications',
        icons: <HiOutlineCube />
    },
    {
        key: 'job_applications',
        label: 'Job Applications',
        path: '/admin/jobApplications',
        icons: <HiOutlineCube />
    },
    {
        key: 'contact_applications',
        label: 'Queries',
        path: '/admin/contactApplications',
        icons: <HiOutlineCube />
    },
    {
        key: 'bookdemo_applications',
        label: 'Demo Request',
        path: '/admin/bookdemoApplications',
        icons: <HiOutlineCube />
    },
    {
        key: 'subscribe_applications',
        label: 'Subscribers',
        path: '/admin/subscribeApplications',
        icons: <HiOutlineCube />
    },
    {
        key: 'users',
        label: 'Users',
        path: '/admin/users',
        icons: <HiOutlineCube />
    },
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
    {
        key: 'settings',
        label: 'Settings',
        path: '/settings',
        icons: <HiOutlineCog />
    },
    {
        key: 'support',
        label: 'Help & Support',
        path: '/support',
        icons: <HiOutlineQuestionMarkCircle />
    },
]