import React, { useEffect, useState } from "react";
import Spinner from '../../components/common/Spinner';
import { getUsers } from "../../services/operations/authAPI";
import CreateUserModal from "./CreateUserModal";

function Users() {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchByUsername, setSearchByUsername] = useState('');
    const [searchbyApplicationDate, setSearchbyApplicationDate] = useState('');
    const [searchbyEmail, setSearchbyEmail] = useState('');
    const [applicantsData, setApplicantsData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const fetchData = async () => {
        setLoading(true)
        const data = await getUsers();
        console.log(data.data);
        if (data) {
            setApplicantsData(data.data);
        }
        else {
            setApplicantsData([]);
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchByUsername = (e) => {
        setSearchByUsername(e.target.value);
    };

    const handleSearchbyApplicationDate = (e) => {
        setSearchbyApplicationDate(e.target.value);
    };

    const handleSearchByEmail = (e) => {
        setSearchbyEmail(e.target.value);
    };

    const filteredApplicants = applicantsData
        .filter(applicant => {
            const inputDate = searchbyApplicationDate ? new Date(searchbyApplicationDate) : null;
            const applicantDate = applicant.created_at ? new Date(applicant.created_at) : null;

            const includesIgnoreCase = (field, query) => {
                if (!query) return true;
                if (!field) return false;
                return field.toLowerCase().includes(query.toLowerCase());
            };

            const datesAreEqual = (date1, date2) => {
                if (!date1 || !date2) return true;
                return date1.toDateString() === date2.toDateString();
            };

            return (
                includesIgnoreCase(applicant.name, searchQuery) &&
                includesIgnoreCase(applicant.username, searchByUsername) &&
                includesIgnoreCase(applicant.email, searchbyEmail) &&
                datesAreEqual(applicantDate, inputDate)
            );
        })
        .sort((a, b) => a.name.localeCompare(b.name));

        const formatDate = (date) => {
            return date.split('T')[0];
        }

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold mb-4">Users</h1>
                <button className={`text-center text-[13px] sm:text-[16px] px-4 py-2 rounded-md shadow-[2px_2px_0px_0px_rgba(255,255,255,0.18)] text-white hover:shadow-none hover:scale-105 transition-all duration-200 bg-primary`} onClick={handleOpenModal}>
                    Create user
                </button>
                <CreateUserModal isOpen={isModalOpen} onClose={handleCloseModal}/>
            </div>
            
            <div className="grid grid-cols-4 gap-4 mt-2 border-b py-4">
                <div className="flex flex-col">
                    <label htmlFor="searchQuery" className="mb-2">Name</label>
                    <input
                        type="text"
                        id="searchQuery"
                        placeholder="Search by Name..."
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
                    />
                </div>

                <div className="flex flex-col">
                    <label htmlFor="searchByUsername" className="mb-2">Username</label>
                    <input
                        type="text"
                        id="searchByUsername"
                        placeholder="Search by Username..."
                        value={searchByUsername}
                        onChange={handleSearchByUsername}
                        className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
                    />
                </div>

                <div className="flex flex-col">
                    <label htmlFor="searchbyEmail" className="mb-2">Email</label>
                    <input
                        type="text"
                        id="searchbyEmail"
                        placeholder="Search by Email..."
                        value={searchbyEmail}
                        onChange={handleSearchByEmail}
                        className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
                    />
                </div>

                <div className="flex flex-col">
                    <label htmlFor="searchbyApplicationDate" className="mb-2">Application Date</label>
                    <input
                        type="date"
                        id="searchbyApplicationDate"
                        placeholder="Search by Application Date..."
                        value={searchbyApplicationDate}
                        onChange={handleSearchbyApplicationDate}
                        className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
                    />
                </div>
            </div>

            <div className="relativ mt-12 overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Username
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Email
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Application Date
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <tr>
                                    <td colSpan="12" className="px-6 py-4 text-center">
                                        <Spinner />
                                    </td>
                                </tr>
                                :
                                filteredApplicants.length > 0 ? (
                                    filteredApplicants.map((applicant, index) => (
                                        <tr
                                            key={index}
                                            className="bg-white border-b"
                                        >
                                            <td
                                                scope="row"
                                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                            >
                                                {applicant.name}
                                            </td>
                                            <td className="px-6 py-4">{applicant.username}</td>
                                            <td className="px-6 py-4">{applicant.email}</td>
                                            <td className="px-6 py-4">{formatDate(applicant.created_at)}</td>
                                            <td className="px-6 py-4">{applicant.is_active ? 'Active' : 'Deactive'}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="12" className="px-6 py-4 text-center">
                                            No Records Found
                                        </td>
                                    </tr>
                                )

                        }
                    </tbody>
                </table>

                <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4" aria-label="Table navigation">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">Showing <span className="font-semibold text-gray-900 dark:text-white">1-10</span> of <span className="font-semibold text-gray-900 dark:text-white">{filteredApplicants.length}</span></span>
                    <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                        <li>
                            <a href="#" className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</a>
                        </li>
                        <li>
                            <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">1</a>
                        </li>
                        <li>
                            <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">2</a>
                        </li>
                        <li>
                            <a href="#" aria-current="page" className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">3</a>
                        </li>
                        <li>
                            <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">4</a>
                        </li>
                        <li>
                            <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">5</a>
                        </li>
                        <li>
                            <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Users;
