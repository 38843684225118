import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import PageNotFound from "./pages/PageNotFound";

import ScrollToTop from "./components/common/ScrollToTop";
import Login from "./pages/Login";
import PrivateRoute from "./components/layout/PrivateRoute";
import Layout from "./components/layout/Layout";
import Dashboard from "./pages/Dashboard";
import OpenLayout from "./components/layout/OpenLayout";

import axios from 'axios';
import InternshipApplications from "./pages/admin/InternShipApplications";
import JobApplications from "./pages/admin/JobApplications";
import ContactApplications from "./pages/admin/ContactApplications";
import SubscribeApplications from "./pages/admin/SubscribeApplications";
import BookdemoApplications from "./pages/admin/BookdemoApplications";
import Users from "./pages/admin/Users";
import ChanegPassword from "./pages/admin/ChangePassword";

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  return (
    <div>
      <ScrollToTop />

      <Routes>
        {/* Open Routes */}
        <Route path="/" element={<OpenLayout />}>
          <Route index element={<Home />} />
          <Route index path="/about" element={<About />} />
          <Route index path="/career" element={<Career />} />
          <Route index path="/contact" element={<Contact />} />
          {/* <Route index path="*" element={<PageNotFound link={'/'}/>} /> */}
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/changePassword/:token" element={<ChanegPassword />} />

        {/* Private Route - for Only Logged in User */}
        <Route
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route path="admin/dashboard" element={<Dashboard />} />
          <Route path="admin/internshipApplications" element={<InternshipApplications />} />
          <Route path="admin/jobApplications" element={<JobApplications />} />
          <Route path="admin/contactApplications" element={<ContactApplications />} />
          <Route path="admin/bookdemoApplications" element={<BookdemoApplications />} />
          <Route path="admin/subscribeApplications" element={<SubscribeApplications />} />
          <Route path="admin/users" element={<Users />} />
          {/* <Route path="*" element={<PageNotFound link={'/admin/dashboard'}/>} /> */}
        </Route>

        {/* 404 Page */}
        <Route path="*" element={<PageNotFound link={'/'}/>} />
      </Routes>
    </div>
  );
}

export default App;
