import { toast } from "react-hot-toast";
import { setUser } from "../../redux/slices/authSlice";
import { apiConnector } from "../apiConnector";
import { setLoading, setToken, setRole } from "../../redux/slices/authSlice";
import { userEndpoints } from "../apis";
import { getToken } from "../../utils/getToken";

const { LOGIN_API, GET_USERS, CREATE_NEW_USER_API, CHANGE_PASSWORD_API } =
  userEndpoints;

export function login(email, password, navigate) {
  // console.log("inside authentication");
  // console.log(email);
  // console.log(password);

  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", LOGIN_API, {
        email,
        password,
      });

      // console.log("LOGIN API RESPONSE............", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      if (response.data.isFirstLogin) {
        navigate(`/changePassword/${response.data.token}`);
      } else {
        toast.success("Login Successful");
        dispatch(setToken(response.data.token));
        dispatch(setUser(response.data.user.user_id));
        const userImage = response.data?.user?.image
          ? response.data.user.image
          : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.firstName} ${response.data.user.lastName}`;
        dispatch(setUser({ ...response.data.user, image: userImage }));
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user_id", response.data.user.id);
        navigate("/admin/dashboard");
      }
    } catch (error) {
      console.log("LOGIN API ERROR............", error);
      toast.error("Login Failed");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function logout(navigate) {
  return (dispatch) => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    toast.success("Logged Out");
    navigate("/login");
  };
}

export const getUsers = async () => {
  const toastId = toast.loading("Loading...");
  // console.log(GET_USERS);
  let result = [];
  const headers = await getToken();
  try {
    const response = await apiConnector("GET", GET_USERS, null, headers, null);
    // console.log("response: ", response);
    if (!response?.data?.success) {
      throw new Error("Could Not Fetch Users.");
    }
    result = response?.data;
    toast.success("Users Feched Successfully");
  } catch (error) {
    console.log("GET_USERS API ERROR............", error);
    toast.error(
      error.response.data.errors[0].type +
        error.response.data.errors[0].value +
        error.response.data.errors[0].msg
    );
    result = error.response?.data;
  }
  toast.dismiss(toastId);
  return result;
};

export const createNewUser = async (formData) => {
  // console.log("inside submit");
  const toastId = toast.loading("Loading...");
  // console.log(CREATE_NEW_USER_API);
  let result = [];
  const headers = await getToken();
  try {
    const response = await apiConnector("POST", CREATE_NEW_USER_API, formData, headers);
    // console.log("response: ", response);
    if (!response?.data?.success) {
      throw new Error("Could Not Submit Form.");
    }
    result = response?.data;
    toast.success("Form Submitted Successfully");
  } catch (error) {
    console.log("CREATE_NEW_USER_API ERROR............", error);
    toast.error("Error Submitting Form");
    result = error.response?.data;
  }
  toast.dismiss(toastId);
  return result;
};

export function passwordReset(formData, token, navigate) {
  // console.log(`${CHANGE_PASSWORD_API}/${token}`);

  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        `${CHANGE_PASSWORD_API}/${token}`,
        formData
      );

      // console.log("CHANGE PASSWORD API RESPONSE............: ", response);

      if (!response.data.success) {
        throw new Error("Could Not Submit Form.");
      }

      toast.success("Password Changed Successfully");
      navigate("/login");
    } catch (error) {
      console.log("CHANGE_PASSWORD_API ERROR............", error);
      toast.error("Error Submitting Form");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}
