const BASE_URL = process.env.REACT_APP_BASE_URL

// AUTH ENDPOINTS
export const userEndpoints = {
  LOGIN_API: BASE_URL + "/auth/login",
  GET_USERS: BASE_URL + "/auth/getUsers",
  CREATE_NEW_USER_API: BASE_URL + "/auth/signup",
  CHANGE_PASSWORD_API: BASE_URL + "/auth/changePassword",
}

// JOB ENDPOINTS
export const jobEndPoints = {
  SUBMIT_JOB_APPLICATION: BASE_URL + "/apply/job",
  GET_JOB_APPLICATIONS: BASE_URL + "/apply/getJobApplications"
}

// FORMS ENDPOINTS
export const formEndPoints = {
  SUBMIT_CONTACT_US_API: BASE_URL + "/reach/contact",
  SUBMIT_BOOKDEMO_API: BASE_URL + "/reach/bookdemo",
  SUBMIT_SUBSCRIPTION_API: BASE_URL + "/reach/subscribe",
  
  GET_SUBSCRIPTION_APPLICATION: BASE_URL + "/reach/getSubscribeApplications",
  GET_BOOKDEMO_APPLICATION: BASE_URL + "/reach/getBookdemoApplications",
  GET_CONTACT_APPLICATION: BASE_URL + "/reach/getContactApplications",
}
// INTERNSHIP ENDPOINTS
export const internshipEndPoints = {
  SUBMIT_INTERNSHIP_APPLICATION: BASE_URL + "/apply/internship",
  GET_INTERNSHIP_APPLICATIONS: BASE_URL + "/apply/getInternshipApplications",
}