import React, { useState } from 'react'
import banner from '../assets/contact/contactus-page-banner.png'
import Banner from '../components/common/Banner';
import { submitContactForm } from '../services/operations/formAPI';
import { Helmet } from 'react-helmet-async';

const Contact = () => {

    // State variables to store form data
    const [fullName, setFullName] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleContactNoChange = (e) => {
        const value = e.target.value;
        if (value.length <= 10) {
            setContactNo(value);
            setErrors((prevErrors) => ({ ...prevErrors, contactNo: '' }));
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, contactNo: 'Contact number cannot exceed 10 digits.' }));
        }
    };

    const validateForm = () => {
        const errors = {};

        if (!fullName.trim()) {
            errors.fullName = 'Full Name is required';
        }
        if (!contactNo.trim()) {
            errors.contactNo = 'Contact No is required';
        }
        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid';
        }
        if (!subject.trim()) {
            errors.subject = 'Subject Name is required';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const resetForm = () => {
        setFullName('');
        setContactNo('');
        setEmail('');
        setSubject('');
        setMessage('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const formData = {
                name: fullName,
                email: email,
                mobile: contactNo,
                subject: subject,
                message: message
            }
            console.log(formData);

            setLoading(true)
            const result = await submitContactForm(formData);
            if (result) {
                resetForm();
                setErrors({});
            }
            setLoading(false)
        }
    };

    return (
        <>
            <Helmet>
                <title>Track Always | Contact Us</title>
            </Helmet>

            <Banner
                title={'Contact Us'}
                description={'We would love to hear from you'}
                banner={banner}
            />

            <div className='mx-auto w-11/12 mt-16 gap-8 flex flex-col lg:flex-row justify-center'>
                <div className='lg:w-[50%]'>
                    <div className='flex justify-center items-center lg:items-start flex-col gap-6 h-full w-11/12 mx-auto'>
                        <p className='text-3xl md:text-4xl lg:text-5xl font-bold text-center lg:text-left md:leading-tight'>Talk to the team, we are here to help</p>
                        <p className='md:text-xl lg::text-2xl text-center lg:text-left'>If you wish to contact us fill in the form and we will get back to you ASAP!</p>
                    </div>
                </div>
                <div className='lg:flex-1 '>
                    <div className='w-11/12 mx-auto'>
                        <h1 className='text-[1.6rem] md:text-[3rem] font-bold'>Request <span className='text-primary'>A Proposal?</span></h1>
                        <div className='h-1 w-14 bg-primary mt-2'></div>
                    </div>
                    <div className='my-8'>
                        <form onSubmit={handleSubmit}>
                            <div className='flex flex-col w-11/12 lg:w-10/12 mx-auto'>
                                <div className='grid md:grid-cols-2 lg:grid-cols-1 gap-6'>
                                    {/* Full Name */}
                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor="fullName" className='text-lg'>Full Name *</label>
                                        <input type="text" id="fullName" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                                        {errors.fullName && <span className="text-red-500">{errors.fullName}</span>}
                                    </div>

                                    {/* Email */}
                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor="email" className='text-lg'>Email Address *</label>
                                        <input type="email" id="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        {errors.email && <span className="text-red-500">{errors.email}</span>}
                                    </div>

                                    {/* Contact No */}
                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor="contactNo" className='text-lg'>Contact No *</label>
                                        <input type="number" id="contactNo" placeholder="Contact No" value={contactNo} onChange={handleContactNoChange} />
                                        {errors.contactNo && <span className="text-red-500">{errors.contactNo}</span>}
                                    </div>

                                    {/* Subject */}
                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor="subject" className='text-lg'>Subject *</label>
                                        <input type="text" id="subject" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                        {errors.subject && <span className="text-red-500">{errors.subject}</span>}
                                    </div>
                                </div>

                                {/* Message */}
                                <div className='flex flex-col gap-2 mt-[5%]'>
                                    <label htmlFor="message" className='text-lg'>Message</label>
                                    <textarea type="text" id="message" onChange={(e) => setMessage(e.target.value)} />
                                </div>

                                <div className='w-full flex justify-center items-center'>
                                    <button type="submit" className='mt-8 py-4 px-12 rounded-md text-xl bg-primary text-white w-full'>
                                        {loading ? <span>Loading...</span> : <span>Submit</span>}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className='w-11/12 mx-auto grid grid-cols-1 md:grid-cols-3 mt-16 gap-6 md:gap-0'>
                <div className='flex flex-col justify-center items-center gap-2'>
                    <p className='text-secondary text-xl text-center'>Sales Enquiries</p>
                    <p className='text-primary text-xl font-bold tracking-wider text-center'>07694061174</p>
                </div>
                <div className='flex flex-col justify-center items-center gap-2'>
                    <p className='text-secondary text-xl text-center'>Customer Support</p>
                    <p className='text-primary text-xl font-bold tracking-wider text-center'>06263201897</p>
                </div>
                <div className='flex flex-col justify-center items-center gap-2'>
                    <p className='text-secondary text-xl text-center'>Device Installation & Service Team</p>
                    <p className='text-primary text-xl font-bold tracking-wider text-center'>09111004441</p>
                </div>
            </div>

            <div className='w-11/12 mx-auto h-[40vh] mt-16'>
                <iframe className='w-full h-full'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3665.6841390624586!2d77.44860101026104!3d23.254578407544173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c43d9b6e83c7d%3A0xf49cec7f079ae3a9!2sTrackalways!5e0!3m2!1sen!2sin!4v1717098212043!5m2!1sen!2sin"
                    style={{ border: "0" }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
            </div>
        </>
    )
}

export default Contact