import React, { useState } from 'react'
import logo from '../../assets/logo/only_logo.png'
import { Link, matchPath, useLocation } from "react-router-dom"
import { FaBars } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
import { NavbarLinks } from "../../data/navbar-links"

const Navbar2 = ({ onBookDemoClick }) => {

    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const matchRoute = (route) => {
        return matchPath({ path: route }, location.pathname)
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <header className='shadow-sm'>
            <nav className='py-4'>
                <div className="flex justify-between items-center gap-8 text-white max-w-[1400px] w-11/12 mx-auto">
                    {/* Logo */}
                    <Link to="/">
                        <img src={logo} alt="logo" width={200} height={42}  />
                    </Link>
                    {/* Navigation links */}

                    {/* desktop view */}
                    <nav className="hidden md:block">
                        <ul className="flex gap-x-6">
                            {NavbarLinks.map((link, index) => (
                                <li key={index}>
                                    <Link to={link.path}>
                                        <p
                                            className={`${matchRoute(link?.path)
                                                ? "text-primary"
                                                : "text-black"
                                                } hover:text-primary transition-all duration-200`}
                                        >
                                            {link.title}
                                        </p>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>

                    <button className={`hidden md:block text-center text-[13px] sm:text-[16px] px-6 py-3 rounded-md font-bold shadow-[2px_2px_0px_0px_rgba(255,255,255,0.18)] text-white  hover:shadow-none hover:scale-105 transition-all duration-200 bg-primary`} onClick={onBookDemoClick}>Book Demo</button>

                    {/* mobile view */}
                    <div className='md:hidden'>
                        <button onClick={toggleMenu} className='focus:outline-none focus:text-gray-500 cursor-pointer z-[1000]'>
                            <FaBars className='h-6 w-6 text-black' />
                        </button>
                    </div>

                    {
                        isOpen && (
                            <div className={`navbar-fixed fixed h-full w-screen md:hidden bg-black/50 backdrop-blur-sm top-0 right-0 z-[50] transition-all duration-200 ${isOpen ? 'navbar-visible' : 'navbar-hidden'}`}>
                                <section className={`text-black bg-white flex flex-col absolute right-0 top-0 h-screen p-9 gap-8 transition-all duration-200 ${isOpen ? 'navbar-visible' : 'navbar-hidden'}`}>
                                    <RxCross2 onClick={toggleMenu} className='mt-0 mb-8 text-3xl cursor-pointer' />

                                    <ul className="flex flex-col gap-y-6 text-black">
                                        {NavbarLinks.map((link, index) => (
                                            <li key={index} className='cursor-pointer'>
                                                <Link to={link.path} onClick={closeMenu}>
                                                    <p
                                                        className={`${matchRoute(link?.path)
                                                            ? "text-primary"
                                                            : "text-black"
                                                            }`}
                                                    >
                                                        {link.title}
                                                    </p>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                    <button className={`md:hidden text-center text-[13px] sm:text-[16px] px-4 py-2 rounded-md font-bold shadow-[2px_2px_0px_0px_rgba(255,255,255,0.18)] text-white hover:shadow-none hover:scale-105 transition-all duration-200 bg-primary`} onClick={onBookDemoClick}>Book Demo</button>
                                </section>
                            </div>
                        )
                    }
                </div>
            </nav>
        </header>
    )
}

export default Navbar2