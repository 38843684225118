import React from 'react'
import { FaArrowUp } from 'react-icons/fa'

const PageTop = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div>
            <div className="flex justify-center items-center rounded-full h-10 w-10 bg-primary cursor-pointer text-white fixed right-4 bottom-4 hover:scale-110 transition-all duration-200 back-to-top" onClick={scrollToTop}>
                <FaArrowUp className='text-xl font-bold' />
            </div>
        </div>
    )
}

export default PageTop