export const getToken = async () => {
  const token = await localStorage.getItem("token");
  console.log(token);

  const headers = {
    Authorization: `JWT ${token}`,
  };

  return headers;
};
