export const NavbarLinks = [
    { 
        title: "Home", 
        path: "/" 
    },
    { 
        title: "About", 
        path: "/about" 
    },
    { 
        title: "Career", 
        path: "/career" 
    },
    { 
        title: "Contact Us", 
        path: "/contact" 
    },
];